<!--
 * @Author: lzh
 * @Date: 2022-07-06 20:15:13
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-10 09:53:19
 * @Description: file content
-->
<template>
  <div class="edit-company-introduction">
    <selfCell
      style="margin-top: 10px"
      title="選擇公司圖片"
    >
      <selfUpload
        v-model="configs.img"
        type="default"
        @change="setValue('img')"
      />
    </selfCell>
    <h3>項-標題配置</h3>
    <el-input
      v-model="configs.title"
      placeholder="請輸入標題"
      @input="setValue('title')"
    ></el-input>
    <h3>項-標題配置</h3>
    <el-input
      v-model="configs.desc"
      placeholder="請輸入標題"
      @input="setValue('desc')"
    ></el-input>
    <h3>項-標題配置</h3>
    <selfCell
      style="margin-top: 10px"
      title="選擇地址icon"
    >
      <selfUpload
        v-model="configs.icon"
        type="default"
        @change="setValue('icon')"
      />
    </selfCell>
    <el-input
      v-model="configs.address"
      placeholder="請輸入地址"
      @input="setValue('address')"
    ></el-input>
    <el-input
      v-model="configs.addressDetail"
      placeholder="請輸入詳細地址"
      @input="setValue('addressDetail')"
    ></el-input>
    <selfCell
      style="margin-top: 10px"
      title="請選擇logo"
    >
      <selfUpload
        v-model="configs.logo"
        type="default"
        @change="setValue('logo')"
      />
    </selfCell>
    <el-input
      v-model="configs.logoDesc"
      placeholder="請輸入公司簡介"
      type="textarea"
      @input="setValue('logoDesc')"
    ></el-input>
    <selfCell
      style="margin-top: 10px"
      title="請選擇圖片"
    >
      <selfUpload
        v-model="configs.manageImage"
        type="default"
        @change="setValue('manageImage')"
      />
    </selfCell>
    <h3>項-標題配置</h3>
    <el-input
      v-model="configs.manageTitle"
      placeholder="請輸入標題"
      @input="setValue('manageTitle')"
    ></el-input>
    <h3>項-標題配置</h3>
    <el-input
      v-model="configs.manageDesc"
      placeholder="請輸入標題"
      type="textarea"
      @input="setValue('manageDesc')"
    ></el-input>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: 'edit-company-introduction',
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          icon: '',
          img: '',
          address: '',
          addressDetail: '',
          logo: '',
          logoDesc: '',
          manageImage: '',
          manageTitle: '',
          manageDesc: '',
        };
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-company-introduction {
}
</style>
